<template>
  <ACRUDTable
    ref="crud_table"
    :fields="
      fields || [
        //'id',
        'name',
        helpers.inputType,
        getHelperObject('imputation'),
        helpers.building,
        getHelperObject('building_type', 'building.building_type_id'),
        getHelperObject('property_unit', 'building.property_unit_id'),
        helpers.ownerName,
        helpers.natureName,
        helpers.periodType,
        helpers.currencyValue,
        helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        helpers.inputType
      ]
    "
    :config="{
      url: 'admin/property_invoice_notes' + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
      route: '/admin/processes/invoice_notes',
      name: 'invoice note',
      lname: 'property_invoice_notes',
      // TODO: form months add a parameter for used months
      params: { _lists: 'months,imputations,building_types,property_units', ...(params || {}) },
      filters: filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, { key: 'imputation', config: { select_all: true } }, 'nature', 'period_type' /*, 'input'*/] : [],
      ...(config || {})
    }"
    @preparedHelpers="onPreparedHelpers"
  />
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'InvoiceNotes',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  methods: {
    onGetData(month_ids) {
      //if (month_id) {
      //this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids }, true)
      //}
    }
  }
}
</script>

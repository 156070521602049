var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
      //'id',
      'name',
      _vm.helpers.inputType,
      _vm.getHelperObject('imputation'),
      _vm.helpers.building,
      _vm.getHelperObject('building_type', 'building.building_type_id'),
      _vm.getHelperObject('property_unit', 'building.property_unit_id'),
      _vm.helpers.ownerName,
      _vm.helpers.natureName,
      _vm.helpers.periodType,
      _vm.helpers.currencyValue,
      _vm.helpers.monthName,
      { key: 'month_id', _classes: 'hide' },
      _vm.helpers.inputType
    ],"config":Object.assign({}, {url: 'admin/property_invoice_notes' + (this.building_id ? ("?conditions=building_id=" + (this.building_id)) : ''),
    route: '/admin/processes/invoice_notes',
    name: 'invoice note',
    lname: 'property_invoice_notes',
    // TODO: form months add a parameter for used months
    params: Object.assign({}, {_lists: 'months,imputations,building_types,property_units'}, (_vm.params || {})),
    filters: _vm.filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, { key: 'imputation', config: { select_all: true } }, 'nature', 'period_type' ] : []},
    (_vm.config || {}))},on:{"preparedHelpers":_vm.onPreparedHelpers}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <InvoiceNotesTable filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import InvoiceNotesTable from './elements/invoiceNotesTable.vue'

export default {
  name: 'InvoiceNotes',
  components: { InvoiceNotesTable }
}
</script>
